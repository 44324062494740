import baseService from '../baseService';
const request = baseService('util');

export default {
  sendEmailCode(params) {
    return request('/sendEmailCode', params, 'post');
  },
  checkEmailCode(params) {
    return request('/checkEmailCode', params, 'post');
  },
  getCaptcha(params) {
    return request('/getCaptcha', params, 'get');
  },
  checkCaptcha(params) {
    return request('/checkCaptcha', params, 'post');
  }
};
