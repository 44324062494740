<template>
  <div class="flex_row checkCode">
    <dog-input v-model="codeValue" @input="onInput"></dog-input>
    <dog-button size="small" @click="sendCode" :disabled="!!countdownTimer">
      {{ countdownTimer ? `已发送${countdown}s` : '发送验证码' }}
    </dog-button>
  </div>
</template>

<script>
  import utilService from '@/api/service/util';

  export default {
    name: 'emailCode',
    props: {
      cur: {
        type: String,
        default: ''
      },
      email: {
        type: String
      }
    },
    data() {
      return {
        codeValue: '',
        countdown: 0,
        countdownTimer: null
      };
    },
    watch: {
      cur: {
        handler(newVal) {
          this.codeValue = newVal;
        },
        immediate: true
      }
    },
    methods: {
      onInput(val) {
        this.$emit('input', val);
      },
      sendCode() {
        if (!this.email) {
          this.$message.error('请输入邮箱后再发送验证码！');
          return;
        }
        utilService.sendEmailCode({ email: this.email }).then(() => {
          this.$message({
            type: 'success',
            message: '邮件验证码已成功发送，5分钟内有效！'
          });
          this.startCountdownTimer();
        });
      },
      startCountdownTimer() {
        this.countdown = 60;
        this.countdownTimer = setInterval(() => {
          if (this.countdown <= 1) {
            clearInterval(this.countdownTimer);
            this.countdownTimer = null;
          }
          this.countdown--;
        }, 1000);
      }
    },
    destroyed() {
      if (this.countdownTimer) {
        clearInterval(this.countdownTimer);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .checkCode {
    align-items: center;
  }
</style>
