<template>
  <div class="rightFooter">
    <div>Copyright © 2021 XXX</div>
  </div>
</template>

<script>
  export default {
    name: 'rightFooter'
  };
</script>

<style lang="scss" scoped>
  @include b(rightFooter) {
    padding: 16px 0;
    font-size: 14px;
    text-align: center;
    background-color: $background-color-base;
    & > div {
      margin-top: 8px;
      &:first-child {
        margin-top: 0;
      }
    }
  }
</style>
